<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card" v-if="$gate.isAdmin() || $gate.isBet88()">
                        <div class="card-header">
                          <h3 class="card-title mb-0">Registered Players - Admin</h3>
                        </div>

                        <div class="card-header" :class="{ 'border-bottom-0': csvData }">
                          <div class="d-flex gap-3 mb-3">
                            <div class="w-25 transDropdown">
                              <v-select label="name" :options="statusOptions" v-model="selectedStatus"
                                @input="loadPlayers" :placeholder="statusPlaceholder" :disabled="isUploading" />
                            </div>
                            <div class="w-25 input-group">
                              <input type="text" class="form-control" placeholder="Filter by User ID" v-model="filter_user_id">
                              <div class="input-group-append">
                                <button class="btn btn-primary" type="button" :disabled="isUploading" @click="loadPlayers()">Search</button>
                              </div>
                            </div>
                          </div>

                          <div id="upload-feature">
                            <form @submit.prevent="handleFileUpload" class="form-inline">
                                <div class="form-group col-md-4">
                                    <input class="custom-file-input" :disabled="isUploading" type="file" ref="fileInput" accept="text/csv" @change="handleFileChange">
                                    <label class="custom-file-label" :class="{ selected: selected }">{{ fileName }}</label>
                                </div>
                                <div class="pl-2">
                                  <button class="btn btn-primary" :disabled="isUploading" type="submit">Upload CSV</button>
                                </div>
                                <div v-if="csvData" class="pl-2">
                                  <button class="btn btn-success" :disabled="isUploading" @click="handleImport">
                                    <template v-if="isUploading">
                                      <i class="fas fa-circle-notch fa-spin"></i>
                                      Saving...
                                    </template>

                                    <template v-else>
                                      Save Player
                                    </template>
                                  </button>
                                </div>
                            </form>
                          </div>
                        </div>

                        <!-- import table -->
                        <div v-if="csvData" class="card-body table-responsive p-0">
                          <p class="preview-table__label">Preview data will only show 20 rows:</p>
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col" v-for="header in csvData[0]">{{ formatHeader(header) }}</th>
                              </tr>
                            </thead> 
                            <tbody>
                              <tr v-for="(row, index) in csvData.slice(1)" :key="index">
                                <td v-for="cell in row">{{ cell }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end of import table -->

                        <div class="card-body table-responsive p-0">
                          <!-- registered players table -->
                          <template v-if="registeredPlayers.data.length > 0 && !isLoading">
                            <table class="table table-hover">
                              <thead>
                                <tr>
                                  <th>User ID</th>
                                  <th>Name</th>
                                  <th>Date of Birth</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="registeredPlayer in registeredPlayers.data" :key="registeredPlayer.id">
                                  <td>{{registeredPlayer.player_id}}</td>
                                  <td>{{registeredPlayer.name}}</td>
                                  <td>{{registeredPlayer.date_of_birth}}</td>
                                  <td :class="{'text-success' : registeredPlayer.status === 'block',
                                          'text-danger': registeredPlayer.status === 'unblock'}">
                                    <span>{{ registeredPlayer.status }}</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </template>
                          <!-- end of registered players table -->

                          <template v-if="isLoading">
                            <table class="table table-hover">
                              <tbody>
                              <tr>
                                <td colspan="2" style="text-align: center">
                                  <spinner :spacing=spinnerSpacing />
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </template>

                          <!-- empty table -->
                          <template v-if="registeredPlayers.data.length === 0 && !isLoading && csvData == null">
                            <table class="table table-hover">
                              <thead>
                              <tr>
                                <th>User ID</th>
                                <th>Name</th>
                                <th>Date of Birth</th>
                                <th>Action</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr>
                                <td colspan="4" style="text-align: center">No data was retrieve. Try other date range.</td>
                              </tr>
                              </tbody>
                            </table>
                          </template>
                          <!-- end empty table -->
                        </div>
                        <!-- /card-body -->
                        <div class="card-footer" v-if="!csvData && !isLoading && registeredPlayers.data.length !== 0">
                          <nav aria-label="simple pagination">
                            <ul class="pagination justify-content-start mb-0">
                              <li class="page-item" :class="{ disabled: !paginator.prev_page_url }">
                                <a class="page-link" @click.prevent="getResults(paginator.prev_page_url)" href="#">Previous</a>
                              </li>
                              <li class="page-item" :class="{ disabled: !paginator.next_page_url }">
                                <a class="page-link" @click.prevent="getResults(paginator.next_page_url)" href="#">Next</a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                    </div> <!-- /card -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Papa from 'papaparse/papaparse.min';
    import Spinner from "vue-simple-spinner";

    const fileInputLabel = 'Select a CSV file';

    export default {
        components: {
          Spinner,
        },
        data() {

            return {
                registeredPlayers: {
                  data: []
                },

                filter_user_id: "",

                statusOptions: [
                  { name: 'Blocked', value: 'unblock' },
                  { name: 'Active', value: 'block' }
                ],
                statusPlaceholder: "Filter by Status", 
                selectedStatus: null,

                csvData: null,
                fileName: fileInputLabel,
                selected: false,
                isUploading: false,

                isLoading: true,
                spinnerSpacing: 20,
                paginator: {},
                per_page: 20,
            };
        },
        methods: {
            handleFileUpload() {
                const fileInput = this.$refs.fileInput;
                const file = fileInput.files[0];
                
                if (!file) {
                  return Swal.fire('Error', 'Please select a CSV file', 'error');
                } 
                  
                this.parseCSV(file);
            },
            handleFileChange(event) {
                const fileInputValue = event.target.value;
                this.fileName = (fileInputValue) ? fileInputValue.split("\\").pop() : fileInputLabel;
                this.selected = true;
            },
            parseCSV(file) {
                this.isUploading = true;
                this.registeredPlayers.data = [];
                this.paginator = {};

                const reader = new FileReader();
                
                reader.onload = (event) => {
                    let csvPreviewData = [];

                    Papa.parse(event.target.result, {
                      preview: 20,
                      step: row => {
                        csvPreviewData.push(row.data);
                      }
                    });
                    
                    if (csvPreviewData.length > 0) {
                        this.csvData = csvPreviewData;
                    } else {
                        alert('The CSV file is empty or has no valid data.');
                    }
                };
                reader.readAsText(file);
                this.isUploading = false;
            },
            formatHeader(header) {
                return header
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');
            },
            async handleImport() {
                this.isUploading = true;

                const fileInput = this.$refs.fileInput;
                let formData = new FormData();
                formData.append("csv", fileInput.files[0]);

                try {
                  const response = await axios.post('/api/v1/import/registered_players', formData, {
                      headers: {
                        'Content-Type': 'multipart/form-data'
                      }
                  });

                  this.isUploading = false;

                  if(response.status !== 200) { 
                    Swal.fire(
                      'Error!',
                        error.message + ". Please check data to import, or contact administrator" ,
                      'error'
                    )
                    return;
                  }    

                  Swal
                    .fire('Imported!', response.data.message, 'success')
                    .then((result) => {
                      if(result.isConfirmed || result.isDismissed) {
                        this.$refs.fileInput.value = null;
                        this.fileName    = fileInputLabel;
                        this.csvData = null;
                        this.loadPlayers();
                      }
                    });
                  
                  this.isUploading = false;
                }
                catch (e) {
                  let errorMessage = e.message + ". Please check data to import, or contact administrator";
                 
                  if(e.response) {
                    const { message, errors } = e.response.data;
                    errorMessage = `${message} `;
                    for(let error in errors) {
                      errorMessage += errors[error].toString(" ");
                    }
                  } 

                  Swal.fire('Error!', errorMessage , 'error');
                  this.isUploading = false;
                } 
            },

            async loadPlayers() {
                this.csvData = null;
                this.isLoading = true;
                this.$Progress.start();

                const params = { per_page: this.per_page };

                if(this.filter_user_id) {
                  params.filter_user_id = this.filter_user_id;
                }

                if(this.selectedStatus) {
                  params.filter_status  = this.selectedStatus.value;
                }
                
                await axios.get('/api/v1/registered-players', { params }).then(({ data }) => {
                  this.registeredPlayers = data;

                  this.paginator = {
                    prev_page_url: data.prev_page_url,
                    next_page_url: data.next_page_url
                  }

                  this.isLoading = false;
                  this.$Progress.finish();
                }).catch(err => {
                  Swal.fire(
                      'Error loading registered players!',
                      err.message + ". Please try again or ask support.",
                      'error'
                  );
                  this.isLoading = false;
                  this.$Progress.finish();
                })
            },

            getResults(url) {
              this.csvData = null;
              this.isLoading = true;
              this.$Progress.start();

              axios.get(url).then(({ data }) => {
                this.registeredPlayers = data;
                this.paginator = {
                  prev_page_url: data.prev_page_url,
                  next_page_url: data.next_page_url
                }
                this.isLoading = false;
                this.$Progress.finish();
              }).catch(error => {
                Swal.fire(
                    'Error in loading next page!',
                    error.message + ". Please try again or ask support." ,
                    'error'
                );
                this.isLoading = false;
                this.$Progress.finish();
              })
            },
        },

        created() {
          this.loadPlayers();
        }
    };
</script>
<style>
    .custom-file-label::after {
        left: 0;
        right: auto;
        border-left-width: 0;
        border-right: inherit;
    }
    .preview-table__label {
      padding: 0.5rem 1.5rem 0.3rem;
      margin: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      background: rgba(0, 0, 0, 0.03);
      font-weight: bold;
    }
</style>
