<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Games</h3>
                        </div>

                        <div v-if="Object.keys(games).length" class="card-body table-responsive p-0">
                          <table id="dataTable" class="table table-hover">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Game</th>
                                <th>Provider</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(game, index) in games" :key="game.id">
                                <td>{{ index + 1 }}</td>
                                <td>{{game.game}}</td>
                                <td>{{game.provider}}</td>
                                <td>
                                  <button
                                    :class="{'btn btn-sm btn-success' : game.status.toLowerCase() === 'disable',
                                        'btn btn-sm btn-danger': game.status.toLowerCase() === 'enable'}"
                                      @click="toggleStatus(game, $gate)">
                                    {{game.status.toLowerCase()}}
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <template v-if="Object.keys(games).length <= 0">
                          <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                              <thead>
                              <tr>
                                <th>#</th>
                                <th>Game</th>
                                <th>Provider</th>
                                <th>Status</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr>
                                <td colspan="5" style="text-align: center">No data was retrieve. Try other date range.</td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </template>
                    </div> <!-- /card -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                games: {},
            };
        },
        methods: {
            async loadGames() {
                this.csvData = null;
                this.isLoading = true;
                await axios.get('/api/v1/imported_games').then(({ data }) => {
                  this.games = data.data;
                  this.isLoading = false;
                }).catch(err => console.error(err))
            },


            async toggleStatus(game, roleGate) {
              if(roleGate.isAdmin()) {
                game.status = game.status.toLowerCase() === 'enable' ? 'disable' : 'enable';
                await axios.patch('/api/v1/imported_games/' + game.id, {
                    status: game.status
                  }).then(response => {
                    if(response.status === 200) {
                      this.$auditrail.insertLog('reports-games', `Updated ${game.game} status to ${game.status}`);
                    }
                  }).catch(err => console.error(err))
              } else {
                Swal.fire(
                  'Contact Administrator',
                  'Your account is not authorized to perform this action.',
                  '401'
                );
              }
            },
        },

        created() {
          this.loadGames();
        },
    };
</script>
