<template>
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card" v-if="$gate.isAdmin() || $gate.isBet88()">
            <div class="card-header">
              <h3 class="card-title">Players Balance Report</h3>
            </div>
            <div class="card-header d-none">
              <div class="card-tools">
                <div class="input-group">
                  <input type="text" class="form-control col-md-4 float-right" v-model="playerId" placeholder="Search a player balance" @keyup.enter="loadPlayersBalance" @blur="loadPlayersBalance" />
                </div>
              </div>
            </div>

            <div class="card-body table-responsive p-0">
              <!-- players balance table -->
              <template v-if="playersBalance.data.length > 0 && !isLoading">
                <table id="dataTable" class="table table-hover">
                  <thead>
                  <tr>
                    <th>User ID</th>
                    <th>Balance</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(playerBalance) in playersBalance.data" :key="playerBalance.id">
                    <td>{{ playerBalance.player_id }}</td>
                    <td>{{ playerBalance.balance | isNotNull | formatAmountFilter }}</td>
                  </tr>
                  </tbody>
                </table>
              </template>
              <!-- end of players balance table -->

              <template v-if="isLoading">
                <table class="table table-hover">
                  <tbody>
                  <tr>
                    <td colspan="2" style="text-align: center">
                      <spinner :spacing=spinnerSpacing />
                    </td>
                  </tr>
                  </tbody>
                </table>
              </template>

              <!-- empty table -->
              <template v-if="playersBalance.data.length === 0 && !isLoading">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th>User ID</th>
                    <th>Balance</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td colspan="2" style="text-align: center">No data was retrieve.</td>
                  </tr>
                  </tbody>
                </table>
              </template>
              <!-- end empty table -->
            </div>
            <!-- end of card-body -->
            <div class="card-footer">
              <nav aria-label="simple pagination">
                <ul class="pagination justify-content-start mt-4">
                  <li class="page-item" :class="{ disabled: !paginator.prev_page_url }">
                    <a class="page-link" @click.prevent="getResults(paginator.prev_page_url)" href="#">Previous</a>
                  </li>
                  <li class="page-item" :class="{ disabled: !paginator.next_page_url }">
                    <a class="page-link" @click.prevent="getResults(paginator.next_page_url)" href="#">Next</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div> <!-- /card -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Spinner from "vue-simple-spinner";

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      playersBalance: {
        data: {}
      },
      playerId: null,

      isLoading: true,
      spinnerSpacing: 20,
      paginator: {},
      per_page: 20,
    };
  },
  methods: {
    async loadPlayersBalance() {
      this.isLoading = true;
      this.$Progress.start();

      const params = {
        per_page: this.per_page,
        player_id: this.playerId
      };

      await axios.get('/api/v1/players-balance', { params }).then(({ data }) => {
        this.playersBalance = data;

        this.paginator = {
          prev_page_url: data.prev_page_url,
          next_page_url: data.next_page_url
        }

        this.isLoading = false;
        this.$Progress.finish();
      }).catch(err => {
        Swal.fire(
            'Error loading registered players!',
            err.message + ". Please try again or ask support.",
            'error'
        );
        this.isLoading = false;
        this.$Progress.finish();
      })
    },

    getResults(url) {
      this.csvData = null;
      this.isLoading = true;
      this.$Progress.start();

      axios.get(url).then(({ data }) => {
        this.playersBalance = data;
        this.paginator = {
          prev_page_url: data.prev_page_url,
          next_page_url: data.next_page_url
        }
        this.isLoading = false;
        this.$Progress.finish();
      }).catch(error => {
        Swal.fire(
            'Error in loading next page!',
            error.message + ". Please try again or ask support." ,
            'error'
        );
        this.isLoading = false;
        this.$Progress.finish();
      })
    },
  },

  created() {
    this.loadPlayersBalance();
  },

  filters: {
    isNotNull(value) {
      return (value !== null) ? value : 0;
    },
    formatAmountFilter(value) {
      if (value === '' || value === null) {
        value = 0.00
      }
      let val = Number(value).toFixed(2)
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

};
</script>
