<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-md-10 text-center">
                    <img src="/images/bet88_logo_new.png" class="img-fluid">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        mounted() {
           
        }
    }
</script>
