<template>
  <section class="content">
    <div class="container-fluid">

      <div class="row">

        <div class="col-12">

          <flash message="" flashType=""></flash>

          <div class="card">
            <div class="card-header">
                <h3 class="card-title">Transactions by Date Range and Games Report - Slots, Bingo, and Sportsbook</h3>
            </div>
            
            <div class="card-header">
              <div class="input-group grid-template">
                <div class="transDropdown">
                  <template>
                    <v-select label="categoryName" :options="categoryOptions" v-model="selectedCategory"
                        @input="loadTransactions" :placeholder="categoryPlaceholder"></v-select>
                  </template>
                </div>
                <div class="transDropdown">
                  <v-select multiple label="name" :options="options" v-model="selectedGame"
                      @input="loadTransactions" :placeholder="placeholder"></v-select>
                </div>
                <div class="transDropdown">
                  <template>
                    <v-select label="name" :options="branchOptions" v-model="selectedBranch"
                        @input="loadTransactions" :placeholder="branchPlaceholder"></v-select>
                  </template>
                </div>
              </div>
              <div class="input-group flex-row gap-3">
                <div>
                  <v-select
                      :options="pageOptions"
                      v-model="rowsPerPage"
                      @input="loadTransactions"
                      label="Rows per page"
                      class="page-select"
                  ></v-select>
                </div>
                <div class="reportDates">
                  <date-range-picker
                      ref="picker"
                      :opens="opens"
                      :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd HH:mm:ss' }"
                      :minDate="minDate" :maxDate="maxDate"
                      :timePicker="timePicker"
                      :timePickerSeconds="timePickerSeconds"
                      :timePickerIncrement="timePickerIncrement"
                      :showDropdowns="showDropdowns"
                      v-model="dateRange"
                      :ranges="ranges"
                      @update="loadTransactions"
                      :disabled="isLoading"
                  >
                    <template v-slot:input="picker" style="min-width: 350px;">
                      {{ picker.startDate | formatDateRangeInput }} - {{ picker.endDate | formatDateRangeInput }}
                    </template>
                  </date-range-picker>
                </div>
                <template v-if="isDownloading">
                  <div>
                    <spinner :spacing=downloadSpinnerSpacing />
                  </div>
                </template>
                <template v-if="!isDownloading">
                  <div class="downloadBtn">
                    <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Download
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a
                          class="dropdown-item"
                          href="#"
                          @click="download('csv')"
                      >As CSV</a>
                    </div>
                  </div>
                </template>

                <template>
                  <div>
                    <button class="btn btn-success" @click="downloadCurrentPage"><i class="fas fa-download"></i> Current Page</button>
                  </div>
                </template>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-0">
              <table id="dataTable" class="table table-hover" aria-label="All games transaction by date range selected">
                <thead>
                <tr>
                  <th>Settlement Date (UTC)</th>
                  <th>Settlement Date (GMT+8)</th>
                  <th>Provider</th>
                  <th>Category</th>
                  <th>Game</th>
                  <th>Branch</th>
                  <th>Player ID</th>
                  <th>Wager Type</th>                  
                  <th>Amount</th>
                  <th>Revenue</th>
                  <th>Jackpot Contribution</th>
                  <th>Jackpot Win</th>
                  <th>Transaction ID</th>
                  <th>GP ID</th>
                </tr>
                </thead>
                <tbody>
                <template v-if="transactions.data.length > 0 && !isLoading">
                  <tr v-for="transaction in transactions.data" :key="transaction.id">
                    <td>{{ transaction.settlement_date | formatDate | isNotNull }}</td>
                    <td>{{ transaction.ph_settlement_date | isNotNull }}</td>
                    <td>{{ transaction.game_studio | isNotNull }}</td>
                    <td>{{ transaction.game_type | isNotNull }}</td>
                    <td>{{ transaction.game_name | isNotNull }}</td>                    
                    <td>{{ transaction.outlet_name | isNotNull }}</td>
                    <td>{{ transaction.player_id | isNotNull }}</td>
                    <td>{{ transaction.casino_event_type | isNotNull }}</td>                    
                    <td>{{ transaction.amount | isNotNull | formatAmountFilter }}</td>
                    <td>{{ transaction.revenue | isNotNull | formatAmountFilter }}</td>
                    <td>{{ transaction.jackpot_contribution | isNotNull | formatAmountFilter }}</td>
                    <td>{{ transaction.jackpot_win | isNotNull | formatAmountFilter }}</td>
                    <td>{{ transaction.transactionId | isNotNull }}</td>
                    <td>{{ transaction.gp_id }}</td>
                  </tr>
                </template>
                <template v-if="isLoading">
                  <tr>

                    <td colspan="14" style="text-align: center">
                      <spinner :spacing=spinnerSpacing />
                    </td>

                  </tr>
                </template>
                <template v-if="(transactions.data.length === 0 || !transactions.data) && !isLoading">
                  <tr>
                    <td colspan="14" style="text-align: center">
                      <span>No data found within those dates</span>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <nav aria-label="simple pagination">
                <ul class="pagination justify-content-start mt-4">
                  <li class="page-item" :class="{ disabled: !paginator.prev_page_url }">
                    <a class="page-link" @click.prevent="getResults(paginator.prev_page_url)" href="#">Previous</a>
                  </li>
                  <li class="page-item" :class="{ disabled: !paginator.next_page_url }">
                    <a class="page-link" @click.prevent="getResults(paginator.next_page_url)" href="#">Next</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <!-- /.card -->
        </div>
      </div>

    </div>
  </section>
</template>

<script>

import moment from "moment";
import Spinner from 'vue-simple-spinner';


export default {
  components: {
    Spinner,
  },
  data () {
    let startDate = moment().subtract(8.5, 'hour').format('YYYY-MM-DD HH:mm:ss');
    let endDate = moment().format('YYYY-MM-DD HH:mm:ss');
    const ordinaryMinDate = moment('2022-01-01 00:00:00').format('YYYY-MM-DD HH:mm:ss');
    const memberMinDate = moment('2024-01-15 00:00:00').format('YYYY-MM-DD HH:mm:ss');

    return {
      transactions: {
        data: {}
      },
      paginator: {},
      bingoMinDate: moment('2023-01-15 00:00:00').format('YYYY-MM-DD HH:mm:ss'),
      memberMinDate: moment('2024-01-15 00:00:00').format('YYYY-MM-DD HH:mm:ss'),

      //date-range-picker params
      opens: 'center',
      dateRange: {
        startDate,
        endDate
      },
      minDate: this.$gate.isMember() ? memberMinDate : ordinaryMinDate,
      maxDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      timePicker: true,
      timePickerSeconds: true,
      timePickerIncrement: 1,
      showDropdowns: true,
      ranges: this.setRanges(),
      //end date-range-picker params

      //dropdown options
      options: [],
      selectedGame: [],
      placeholder: "Filter by Game",

      //category dropdown options
      categoryOptions: [],
      categoryPlaceholder: "Filter by Category",
      selectedCategory: null,

      //branch dropdown options
      branchOptions: [
        { name: 'GRAND88_LAOAG', value: 'GRAND88_LAOAG' },
        { name: 'TRIPLE_8_EGAMES_MARIKINA', value: 'TRIPLE_8_EGAMES_MARIKINA' },
      ],
      branchPlaceholder: "Filter by Branch",
      selectedBranch: null,

      isLoading: true,
      spinnerSpacing: 20,

      userRole: '',

      isDownloading: false,
      downloadSpinnerSpacing: 20,

      //page options
      pageOptions: [20, 50, 100],
      rowsPerPage: 20,
    }
  },
  methods: {
    logAuditTrail(message) {
      if (!message) return;
      
      let logMessage    = message;

      if (message.includes(":date")) {
        const startDate   = moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm:ss');
        const endDate     = moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm:ss');
        logMessage = logMessage.replace(":date", `${startDate} - ${endDate}`);
      }

      if (message.includes(":category")) {
        const categoryName = this.selectedCategory ? this.selectedCategory.categoryName : "ALL";
        logMessage = logMessage.replace(":category", categoryName);
      }

      if (message.includes(":branch")) {
        const branchName = this.selectedBranch ? this.selectedBranch.name : "ALL";
        logMessage = logMessage.replace(":branch", branchName);
      }

      if (message.includes(":games")) {
        const games = this.selectedGame.length ? this.selectedGame.map(game => game.name).join(", ") : "ALL";
        logMessage = logMessage.replace(":games", games);
      }

      this.$auditrail.insertLog('reports-transactions', logMessage);
    },

    async loadTransactions(){
      this.isLoading = true;
      this.$Progress.start();

      const selectedGames = this.selectedGame.map(game => game.id);

      this.logAuditTrail(`Viewed (:date) (:branch) (:category) (:games)`);
    
      await axios.get("/api/v1/transactions", {
        params: {
          startDate: moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm:ss'),
          gameId: selectedGames,
          category: this.selectedCategory !== null ? this.selectedCategory.categoryName : 'ALL',
          results: this.rowsPerPage ?? 20,
          branch: this.selectedBranch !== null ? this.selectedBranch.name : 'ALL'
        }
      }).then(({ data }) => {
        this.transactions = data;
        this.paginator = {
          prev_page_url: data.prev_page_url,
          next_page_url: data.next_page_url
        }
        this.isLoading = false;
        this.$Progress.finish();
      }).catch(error => {
        Swal.fire(
            'Error loading!',
            error.message + ". Please contact the web administrator" ,
            'error'
        );
        this.$Progress.finish();
        this.isDownloading = false;
      });
    },
    async getResults(url) {
      this.isLoading = true;
      this.$Progress.start();

      const selectedGames = this.selectedGame.map(game => game.id);

      await axios.get(url, {
        params: {
          gameId: selectedGames,
          category: this.selectedCategory !== null ? this.selectedCategory.categoryName : 'ALL'
        }
      }).then(({data}) => {
        this.transactions = data;
        this.paginator = {
          prev_page_url: data.prev_page_url,
          next_page_url: data.next_page_url
        }
        this.isLoading = false;
        this.$Progress.finish();
      }).catch(error => {
        Swal.fire(
            'Error fetching page content!',
            error.message + ". Please contact the web administrator" ,
            'error'
        );
        this.$Progress.finish();
        this.isDownloading = false;
      });
    },
    async getGames() {
      await axios.get(`/api/v1/games`)
        .then(({ data }) => {
          this.options = data.data;

          // if user role is category only, restrict list of games filter
          if(this.$gate.isUserCategoryRoleOnly()) {
            if (this.selectedCategory.categoryName === "SLOTS") {
              this.options = this.options.filter(game => !["BINGO", "SPORTSBOOK"].includes(game.gameType)); 
            } else {
              this.options = this.options.filter(game => game.gameType === this.selectedCategory.categoryName);
            }
          }
        }).catch(error => {
            Swal.fire(
                'Error loading games list!',
                error.message + ". Please contact the web administrator" ,
                'error'
            );
            this.$Progress.finish();
            this.isDownloading = false;
          });
    },
    async download(type) {
      this.$Progress.start();
      this.isDownloading = true;

      const selectedGames = this.selectedGame.map(game => game.id);
      const category = this.selectedCategory !== null ? this.selectedCategory.categoryName : 'ALL';
      const branch = this.selectedBranch !== null ? this.selectedBranch.name : 'ALL';

      flash('Downloading now... Please wait...', 'alert alert-success');

      let data = await axios.get('/api/v1/export/gen')
      let key = data.data
      if(key === "currentlyDownloading"){
        Swal.fire(
          'Downloading in Progress!',
          'This user is currently downloading a report. Please try again after 5 mins of first downloading time.',
          'warning'
        );
        this.$Progress.finish();
        this.isDownloading = false;
      } else {
        this.logAuditTrail(`Requested to Download ${type.toUpperCase()} (:date) (:branch) (:category) (:games)`);

        let url = `${window.location.origin}/api/v1/export/transactions?
        startDate=${moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm:ss')}&
        endDate=${moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm:ss')}&
        type=${type}&category=${category}&role=${this.userRole}&
        encryptionKey=${key}&selectedGames=${selectedGames}&branch=${branch}`;

        const filename = 'Transactions_' +
            moment(this.dateRange.startDate).format('YYYY_MM_DD_HH_mm_ss') + '_to_' +
            moment(this.dateRange.endDate).format('YYYY_MM_DD_HH_mm_ss') +
            "." + type;

        await axios.get(url, {
          responseType: 'blob'
        }).then(response => {
          // Create a blob from the response data
          const blob = new Blob([response.data], {type: 'text/csv'});

          // Create a link element to trigger the download
          const url = window.URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();

          // Cleanup: remove the link and revoke the URL
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);

          this.$Progress.finish();
          this.isDownloading = false;
        }).catch(error => {
          Swal.fire(
              'Error on download!',
              error.message + ". Please contact the web administrator" ,
              'error'
          );
          this.$Progress.finish();
          this.isDownloading = false;
        });
      }
    },
    setRanges() {
      let today = new Date()
      today.setHours(0, 0, 0, 0)
      let todayEnd = new Date()
      todayEnd.setHours(23, 59, 59, 999);

      let yesterdayStart = new Date()
      yesterdayStart.setDate(today.getDate() - 1)
      yesterdayStart.setHours(0, 0, 0, 0);

      let yesterdayEnd = new Date()
      yesterdayEnd.setDate(today.getDate() - 1)
      yesterdayEnd.setHours(23, 59, 59, 999);

      let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59, 999);

      return {
        'Today': [today, todayEnd],
        'Yesterday': [yesterdayStart, yesterdayEnd],
        'This month': [thisMonthStart, thisMonthEnd],
        'This year': [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31, 23, 59, 59, 999)
        ],
        'Last month': [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59, 999)
        ],
      }
    },

    downloadCurrentPage() {
      this.logAuditTrail(`Requested to Download Current Page (:date) (:branch) (:category) (:games)`);

      const table = document.getElementById('dataTable');
      const rows = Array.from(table.querySelectorAll('tr'));
      const csvData = [];
      rows.forEach(row => {
        const cells = Array.from(row.querySelectorAll('th, td'));
        const rowData = cells.map(cell => {
          let cellText = cell.innerText;
          if (cellText.includes(',')) {
            cellText = `"${cellText}"`;
          }
          return cellText;
        }).join(',');
        csvData.push(rowData);
      });
      const csvString = csvData.join('\n');
      const blob = new Blob([csvString], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download',
          'Transactions_' +
          moment(this.dateRange.startDate).format('YYYY-MM-DD') + '_to_' +
          moment(this.dateRange.endDate).format('YYYY-MM-DD') + '.csv');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

  },
  created() {
    this.userRole = this.$gate.isMember() ? 'member' : this.$gate.user.type;

    let options = [
        { categoryName: 'ALL', value: 'All' },
        { categoryName: 'BINGO', value: 'Bingo' },
        { categoryName: 'SLOTS', value: 'Slots' },
        { categoryName: 'SPORTSBOOK', value: 'Sportsbook' }
      ]

    if(this.$gate.isUserCategoryRoleOnly()) {
      if(this.userRole === 'slot') {
        this.categoryOptions.push({ categoryName: 'SLOTS', value: 'Slots' })
      }
      if(this.userRole === 'sportsbook') {
        this.categoryOptions.push({ categoryName: 'SPORTSBOOK', value: 'Sportsbook' })
      }
      if(this.userRole === 'bingo') {
        this.categoryOptions.push({ categoryName: 'BINGO', value: 'Bingo' })
        this.minDate = this.bingoMinDate
      }
      this.selectedCategory = this.categoryOptions[0]
    } else {
      // member roles set up go here
      if(this.userRole === 'member') {
        this.minDate = this.memberMinDate
      }
      this.categoryOptions = options
    }
      
    this.getGames();
    this.loadTransactions();
},
  filters: {
    isNotNull(value) {
      return (value !== null) ? value : '';
    },
    formatDateRangeInput(value) {
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    },
    formatAmountFilter(value) {
      if (value === '' || value === null) {
        value = 0.00
      }
      let val = Number(value).toFixed(2)
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
}

</script>

<style>
  .input-space {
    padding-left: 35px;
  }

  .gap-3 { gap: 15px 30px; }
  
  .flex-row .transDropdown {
    width: auto;
    flex-grow: 1;
  }

  .grid-template {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 10px;
    margin-bottom: 15px;
  }

  .grid-template .transDropdown {
    width: 100%;
  }

  .card-tools {
    text-align: center; 
    width: 100%;
  }

  .page-select {
    width: 110px;
  }
</style>
