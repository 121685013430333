export default class AuditTrail{
    constructor(user){
        this.user = user;
    }

    insertLog(category, description){
        axios.post("/api/audit-trail", {
            category : category,
            description : description
        }).catch(error => console.error(error));
    }

    getUserActivity(){
        return axios.get("/api/audit-trail/user-activity", { 
            params: {
                user_id : this.user.id
            }
        }).catch(error => console.error(error));
    }
}
