<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card" v-if="$gate.isAdmin() || $gate.isBet88()">
                        <div class="card-header">
                          <h3 class="card-title mb-0">Registered Players Report</h3>
                        </div>

                        <div class="card-header">
                          <div class="d-flex gap-3">
                            <div class="w-25 transDropdown">
                              <v-select label="name" :options="statusOptions" v-model="selectedStatus"
                                @input="loadPlayers" :placeholder="statusPlaceholder" />
                            </div>
                            <div class="w-25 input-group d-none">
                              <input type="text" class="form-control" placeholder="Filter by User ID" v-model="filter_user_id">
                              <div class="input-group-append">
                                <button class="btn btn-primary" type="button" @click="loadPlayers()">Search</button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="card-body table-responsive p-0">
                          <template v-if="isLoading">
                            <table class="table table-hover">
                              <tbody>
                              <tr>
                                <td colspan="4" style="text-align: center">
                                  <spinner :spacing=spinnerSpacing />
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </template>

                          <!-- registered players table -->
                          <template v-if="!isLoading">
                            <table id="dataTable" class="table table-hover">
                              <thead>
                                <tr>
                                  <th>User ID</th>
                                  <th>Name</th>
                                  <th>Date of Birth</th>
                                  <th>Action</th>
                                </tr>
                              </thead>

                              <tbody>
                                <template v-if="registeredPlayers.data.length === 0">
                                  <tr>
                                    <td colspan="4" style="text-align: center">No data was retrieve.</td>
                                  </tr>
                                </template>  
                                <template v-if="registeredPlayers.data.length > 0">
                                  <tr v-for="registeredPlayer in registeredPlayers.data" :key="registeredPlayer.id">
                                    <td>{{ registeredPlayer.player_id }}</td>
                                    <td>{{ registeredPlayer.name }}</td>
                                    <td>{{ registeredPlayer.date_of_birth }}</td>
                                    <td>
                                      <button
                                          :class="{'btn btn-sm btn-success' : registeredPlayer.status === 'block',
                                            'btn btn-sm btn-danger': registeredPlayer.status === 'unblock'}"
                                          @click="toggleStatus(registeredPlayer, $gate)">
                                            <i :class="{'fas fa-thumbs-down' : registeredPlayer.status === 'block',
                                                'fas fa-thumbs-up': registeredPlayer.status === 'unblock'}"></i>
                                            {{ registeredPlayer.status }}
                                      </button>
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </template>
                          <!-- end of registered players table -->
                        </div>
                        <!-- end of card-body -->
                        <div class="card-footer" v-if="!isLoading && registeredPlayers.data.length !== 0">
                          <nav aria-label="simple pagination">
                            <ul class="pagination justify-content-start mb-0">
                              <li class="page-item" :class="{ disabled: !paginator.prev_page_url }">
                                <a class="page-link" @click.prevent="getResults(paginator.prev_page_url)" href="#">Previous</a>
                              </li>
                              <li class="page-item" :class="{ disabled: !paginator.next_page_url }">
                                <a class="page-link" @click.prevent="getResults(paginator.next_page_url)" href="#">Next</a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                    </div> <!-- /card -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Spinner from "vue-simple-spinner";

    export default {
        components: {
          Spinner,
        },
        data() {
          return {
            registeredPlayers: {
              data: []
            },

            filter_user_id: "",

            statusOptions: [
              { name: 'Blocked', value: 'unblock' },
              { name: 'Active', value: 'block' }
            ],
            statusPlaceholder: "Filter by Status", 
            selectedStatus: null,

            //end date-range-picker params
            isLoading: true,
            spinnerSpacing: 20,

            paginator: {},
            per_page: 20,
          };
        },
        methods: {
            async loadPlayers() {
              this.isLoading = true;
              this.$Progress.start();

              const params = { per_page: this.per_page };

              if(this.filter_user_id) {
                params.filter_user_id = this.filter_user_id;
              }

              if(this.selectedStatus) {
                params.filter_status  = this.selectedStatus.value;
              }

              await axios.get('/api/v1/registered-players', { params }).then(({ data }) => {
                this.registeredPlayers = data;

                this.paginator = {
                  prev_page_url: data.prev_page_url,
                  next_page_url: data.next_page_url
                }

                this.isLoading = false;
                this.$Progress.finish();
              }).catch(err => {
                Swal.fire(
                    'Error loading registered players!',
                    err.message + ". Please try again or ask support.",
                    'error'
                );
                this.isLoading = false;
                this.$Progress.finish();
              })
            },

            getResults(url) {
              this.csvData = null;
              this.isLoading = true;
              this.$Progress.start();

              axios.get(url).then(({ data }) => {
                this.registeredPlayers = data;
                this.paginator = {
                  prev_page_url: data.prev_page_url,
                  next_page_url: data.next_page_url
                }
                this.isLoading = false;
                this.$Progress.finish();
              }).catch(error => {
                Swal.fire(
                    'Error in loading next page!',
                    error.message + ". Please try again or ask support." ,
                    'error'
                );
                this.isLoading = false;
                this.$Progress.finish();
              })
            },

            async toggleStatus(player, roleGate) {
              if (roleGate.isAdmin()) {
                player.status = player.status === 'unblock' ? 'block' : 'unblock';
                await axios.patch('/api/v1/registered-players/' + player.id, {
                  status: player.status
                }).then(response => {
                  if(response.status === 200) {
                    this.$auditrail.insertLog('reports-users', `Updated ${player.player_id} status to ${player.status}`);
                  } 
                }).catch(error => {
                  Swal.fire(
                      'Unable to change status!',
                      error.message + ". Please try again or ask support." ,
                      'error'
                  );
                  this.isLoading = false;
                  this.$Progress.finish();
                })
              } else {
                Swal.fire(
                    'Contact Administrator',
                    'Your account is not authorized to perform this action.',
                    '401'
                );
              }
            },
        },

        created() {
          this.loadPlayers();
        }
    };
</script>
