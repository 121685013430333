<template>
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Total Revenue Per Category</h3>
            </div>

            <div class="card-header">
              <div class="card-tools">
                  <div class="reportDates input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
                          <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                        </svg>
                      </div>
                    </div>
                    <date-range-picker
                        ref="picker"
                        :opens="opens"
                        :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd HH:mm:ss' }"
                        :minDate="minDate" :maxDate="maxDate"
                        :timePicker="timePicker"
                        :timePickerSeconds="timePickerSeconds"
                        :timePickerIncrement="timePickerIncrement"
                        :showDropdowns="showDropdowns"
                        v-model="dateRange"
                        :ranges="ranges"
                        :disabled="isLoading"
                        @update="loadRevenues"
                    >
                      <template v-slot:input="picker" style="min-width: 350px;">
                        {{ picker.startDate | formatDateRangeInput }} - {{ picker.endDate | formatDateRangeInput }}
                      </template>
                    </date-range-picker>
                </div>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-0">
              <table class="table table-hover">
                <thead class="blue">
                  <tr>
                    <th>Category</th>
                    <th>Total Revenue</th>
                    <th>Total Jackpot Contribution</th>
                    <th>Grand Total</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="Object.keys(total_revenue).length > 0 && !isLoading">
                    <tr v-for="(value, name) in total_revenue">
                      <td>{{ name | isNotNull }}</td>
                      <td>{{ value.total_revenue | isNotNull | formatAmountFilter }}</td>
                      <td>{{ value.total_jackpot_contribution | isNotNull | formatAmountFilter }}</td>
                      <td>{{ (value.total_revenue - value.total_jackpot_contribution) | isNotNull | formatAmountFilter }}</td>
                    </tr>
                  </template>
                  <template v-if="isLoading">
                    <tr>
                      <td colspan="4" style="text-align: center">
                        <spinner :spacing=spinnerSpacing />
                      </td>
                    </tr>
                  </template>
                  <template v-if="Object.keys(total_revenue).length <= 0 && !isLoading">
                    <tr>
                      <td colspan="4" style="text-align: center">No data was retrieve. Try other date range.</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
        </div>
      </div>

    </div>
  </section>
</template>

<script>

import moment from "moment";
import Spinner from "vue-simple-spinner";

export default {
  components: {
    Spinner,
  },
  data () {
    let startDate = moment().subtract(8.5, 'hour').format('YYYY-MM-DD HH:mm:ss');
    let endDate = moment().format('YYYY-MM-DD HH:mm:ss');

    return {
      total_revenue: {},
      //date-range-picker params
      opens: 'right',
      dateRange: {
        startDate,
        endDate
      },
      minDate: moment('2022-01-01 00:00:00').format('YYYY-MM-DD HH:mm:ss'),
      maxDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      timePicker: true,
      timePickerSeconds: true,
      timePickerIncrement: 1,
      showDropdowns: true,
      ranges: this.setRanges(),
      //end date-range-picker params
      isLoading: true,
      spinnerSpacing: 20,
    }
  },
  methods: {
    loadRevenues(){
      this.isLoading = true;
      this.$Progress.start();

      const startDate   = moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm:ss');
      const endDate     = moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm:ss');

      this.$auditrail.insertLog("reports-revenue_per_category", `Viewed (${startDate} - ${endDate})`);

      axios.get('/api/v1/transactions/revenue-report', {
        params: {
          startDate,
          endDate
        }
      }).then(({ data }) => {
          this.total_revenue = data.data;
          console.log(this.total_revenue);
          this.isLoading = false;
          this.$Progress.finish();
      }).catch(err => {
        console.log(err)
        this.isLoading = false
      });
    },

    setRanges() {
      let today = new Date()
      today.setHours(0, 0, 0, 0)
      let todayEnd = new Date()
      todayEnd.setHours(23, 59, 59, 999);

      let yesterdayStart = new Date()
      yesterdayStart.setDate(today.getDate() - 1)
      yesterdayStart.setHours(0, 0, 0, 0);

      let yesterdayEnd = new Date()
      yesterdayEnd.setDate(today.getDate() - 1)
      yesterdayEnd.setHours(23, 59, 59, 999);

      let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59, 999);

      return {
        'Today': [today, todayEnd],
        'Yesterday': [yesterdayStart, yesterdayEnd],
        'This month': [thisMonthStart, thisMonthEnd],
        'This year': [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31, 23, 59, 59, 999)
        ],
        'Last month': [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59, 999)
        ],
      }
    }

  },
  created() {
    this.loadRevenues();
  },
  filters: {
    isNotNull(value) {
      return (value !== null) ? value : '';
    },
    formatDateRangeInput(value) {
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    },
    formatAmountFilter(value) {
      let val = (value).toFixed(2)
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }
}

</script>
