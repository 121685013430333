<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Clear Cache Maintenance</h3>
              </div>
              <div class="card-body">
                <form @submit.prevent="createUser()">
                  <div class="card-tools">
                    <div class="reportDates input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                          </svg>
                        </div>
                      </div>
                      <date-range-picker
                          ref="picker"
                          :opens="opens"
                          :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd HH:mm:ss' }"
                          :minDate="minDate" :maxDate="maxDate"
                          :timePicker="timePicker"
                          :timePickerSeconds="timePickerSeconds"
                          :timePickerIncrement="timePickerIncrement"
                          :showDropdowns="showDropdowns"
                          v-model="dateRange"
                          :ranges="ranges"
                      >
                        <template v-slot:input="picker" style="min-width: 350px;">
                          {{ picker.startDate | formatDateRangeInput }} - {{ picker.endDate | formatDateRangeInput }}
                        </template>
                      </date-range-picker>
                    </div>
                  </div>
                  <div class="card-tools">
                    <button type="button" class="btn btn-sm btn-primary" @click="triggerClear()">
                        <i class="fa fa-trash"></i>
                        Clear Cache
                    </button>

                    <!-- For checking of clear functionality - specific date -->
                    <!-- <button type="button" class="btn btn-sm btn-primary" @click="triggerAdd()">
                        <i class="fa fa-plus"></i>
                        Add Cache
                    </button>
                    <button type="button" class="btn btn-sm btn-primary" @click="triggerCheck()">
                        <i class="fa fa-eye"></i>
                        Check Cache
                    </button> -->
                    <!-- End of checking buttons-->

                  </div>
                </form>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div v-if="!$gate.isAdmin()">
            <not-found></not-found>
        </div>
    </div>
  </section>
</template>
<script>

import moment from "moment";

export default {
  data () {
    let startDate = moment().subtract(1, 'd').startOf('day').format('YYYY-MM-DD HH:mm:ss')
    let endDate = moment().subtract(1, 'd').endOf('day').format('YYYY-MM-DD HH:mm:ss')

    return {
      //date-range-picker params
      opens: 'right',
      dateRange: {
        startDate,
        endDate
      },
      minDate: moment('2022-01-01 HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
      maxDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      timePicker: true,
      timePickerSeconds: true,
      timePickerIncrement: 1,
      showDropdowns: true,
      ranges: this.setRanges(),
      //end date-range-picker params
      isLoading: true,
      oldCacheKey: startDate + '_to_' + endDate,
      cacheKey: '',
    }
  },
  methods: {
    setRanges() {
      let today = new Date()
      today.setHours(0, 0, 0, 0)
      let todayEnd = new Date()
      todayEnd.setHours(23, 59, 59, 999);

      let yesterdayStart = new Date()
      yesterdayStart.setDate(today.getDate() - 1)
      yesterdayStart.setHours(0, 0, 0, 0);

      let yesterdayEnd = new Date()
      yesterdayEnd.setDate(today.getDate() - 1)
      yesterdayEnd.setHours(23, 59, 59, 999);

      let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59, 999);
      
      return {
        'Today': [today, todayEnd],
        'Yesterday': [yesterdayStart, yesterdayEnd],
        'This month': [thisMonthStart, thisMonthEnd],
        'This year': [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31, 23, 59, 59, 999)
        ],
        'Last month': [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59, 999)
        ],
      }
    },

    generateCacheKey(){
      this.oldCacheKey = moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm:ss') + '_to_' + moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm:ss');
      this.cacheKey = this.oldCacheKey.replaceAll(/ |:|-/g, '_');
    },
    
    triggerClear() {
      this.generateCacheKey();

      Swal.fire({
            title: 'Are you sure?',
            text: "Cache will be deleted",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, clear it!'
            }).then((result) => {
              // Send request to the server
              if (result.value) {
                this.$Progress.start();

                axios.delete("/api/cache/"+this.cacheKey).then(()=>{
                  Swal.fire(
                    'Deleted!',
                    'The cache has been deleted.',
                    'success'
                  );
                })
                .catch(() => {
                    this.$Progress.fail();

                    Toast.fire({
                        icon: 'error',
                        title: "Invalid parameter. Please try again."
                    });
                });

                this.$Progress.finish();
                
              }
          })
    },
    
    triggerAdd() {
      this.generateCacheKey();

      Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, add it!'
            }).then((result) => {
              // Send request to the server
              if (result.value) {
                this.$Progress.start();

                axios.post("/api/cache", {
                  cachekey : this.cacheKey
                }).then(()=>{
                  Swal.fire(
                    'Successfully Added!',
                    'The new cache data has been added.',
                    'success'
                  );
                })
                .catch(() => {
                    this.$Progress.fail();

                    Toast.fire({
                        icon: 'error',
                        title: "Invalid parameter. Please try again."
                    });
                });

                this.$Progress.finish();
                
              }
          })
    },
    
    triggerCheck() {
      this.generateCacheKey();

      Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, check it!'
            }).then((result) => {
              // Send request to the server
              if (result.value) {
                this.$Progress.start();

                axios.get("/api/cache/"+ this.cacheKey).then(()=>{
                  Swal.fire(
                    'Success!',
                    'The cache has been show in console log.',
                    'success'
                  );
                })
                .catch(() => {
                    this.$Progress.fail();

                    Toast.fire({
                        icon: 'error',
                        title: "Invalid parameter. Please try again."
                    });
                });

                this.$Progress.finish();
                
              }
          })
    }

  },
  filters: {
    formatDateRangeInput(value) {
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    }
  }
}

</script>
