<template>
  <div :class="flashType" role="alert" v-show="show">
    {{ body }}
  </div>
</template>

<script>
export default {
  props: ['message'],
  data() {
    return {
      show: false,
      body: '',
      flashType: 'alert alert-success',
    }
  },
  created() {
    if(this.message) {
      this.flash(this.message, this.flashType);
    }

    window.events.$on('flash', (message, flashType) => this.flash(message, flashType));
  },
  methods: {
    flash(message, flashType) {
      this.show = true;
      this.body = message;
      this.flashType = flashType;

      setTimeout(() => {
        this.hide()
      }, 6000)
    },
    hide() {
      this.show = false
    }
  }
}
</script>