<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card" v-if="$gate.isAdmin() || $gate.isBet88()">
                        <div class="card-header">
                            <h3 class="card-title">Games</h3>
                        </div>
                        
                        <div class="card-header">
                            <div class="card-tools">
                              <div id="upload-feature">
                                <form @submit.prevent="handleFileUpload" class="form-inline">
                                    <div class="form-group col-md-4">
                                        <input class="custom-file-input" type="file" ref="fileInput" accept="text/csv" @change="handleFileChange">
                                        <label class="custom-file-label" :class="{ selected: selected }">{{ fileName }}</label>
                                    </div>
                                    <div class="pl-2">
                                      <button class="btn btn-primary" type="submit">Upload CSV</button>
                                    </div>
                                    <div v-if="csvData" class="pl-2">
                                      <button class="btn btn-success" @click="handleImport">Save Games</button>
                                    </div>
                                </form>
                              </div>
                            </div>
                        </div>

                        <!-- import table -->
                        <div v-if="csvData" class="card-body table-responsive p-0">
                            <table class="table table-striped">
                                <thead>
                                  <tr>
                                    <th scope="col" v-for="header in csvData[0]">{{ formatHeader(header) }}</th>
                                  </tr>
                                </thead> 
                                <tbody>
                                  <tr v-for="(row, index) in csvData.slice(1)" :key="index">
                                    <td v-for="cell in row">{{ cell }}</td>
                                  </tr>
                                </tbody>
                              </table>
                        </div>
                        <!-- end of import table -->

                        <!-- registered games table -->
                        <div v-if="Object.keys(games).length && !isLoading" class="card-body table-responsive p-0">
                          <table class="table table-hover">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Game</th>
                                <th>Provider</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(game, index) in games" :key="game.id">
                                <td>{{ index + 1 }}</td>
                                <td>{{game.game}}</td>
                                <td>{{game.provider}}</td>
                                <td :class="{'text-success' : game.status.toLowerCase() === 'disable',
                                        'text-danger': game.status.toLowerCase() === 'enable'}">
                                  <span> {{game.status}} </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end of registered games table -->
                    </div> <!-- /card -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Papa from 'papaparse/papaparse.min';

    export default {
        data() {
            return {
              games: {},

              csvData: null,
              fileName: 'Select a CSV file',
              selected: false,
              isUploading: false,
              isLoading: false
            };
        },
        methods: {
            handleFileUpload() {
                this.games = {};

                const fileInput = this.$refs.fileInput;
                const file = fileInput.files[0];
                
                if (file) {
                    this.parseCSV(file);
                } else {
                    Swal.fire(
                        'Error',
                        'Please select a CSV file',
                        'error'
                    );
                }
            },
            handleFileChange(event) {
                const fileInput = event.target;
                this.fileName = fileInput.value.split("\\").pop();
                this.selected = true;
            },
            parseCSV(file) {
                this.isUploading = true;
                const reader = new FileReader();
                
                reader.onload = (event) => {
                    const parsedData = Papa.parse(event.target.result);
                    
                    if (parsedData.data.length > 0) {
                        this.csvData = parsedData.data;
                    } else {
                        alert('The CSV file is empty or has no valid data.');
                    }
                };
                reader.readAsText(file);
                this.isUploading = false;
            },
            formatHeader(header) {
                return header
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');
            },
            handleImport() {
                const gamesImport = JSON.stringify(this.convertCsvDataToAssociativeArray(this.csvData));
                
                axios.post('/api/v1/import/games', {
                    'data': gamesImport
                })
                .then(()=> {
                    Swal.fire(
                        'Imported!',
                        'All games has been saved.',
                        'success'
                    ),
                    this.csvData = null
                    this.loadGames()
                })
                .catch(
                    error => {
                        Swal.fire(
                            'Error!',
                             error.message + ". Please check data to import, or contact administrator" ,
                            'error'
                        )
                    }
                )
                .finally(() => {
                  this.isUploading = false;
                });
            },
            convertCsvDataToAssociativeArray() {
                if (!this.csvData || this.csvData.length === 0) {
                    return {};
                }

                const headers = this.csvData[0];
                const data = this.csvData.slice(1);

                return data
                    .filter(row => row.some(cell => cell.trim() !== ""))
                    .map(row => {
                    const rowData = {};
                    headers.forEach((header, index) => {
                        rowData[header.toLowerCase()] = row[index];
                    });
                    return rowData;
                });
            },

            async loadGames() {
                this.csvData = null;
                this.isLoading = true;
                await axios.get('/api/v1/imported_games').then(({ data }) => {
                  this.games = data.data;
                  this.isLoading = false;
                }).catch(err => console.error(err))
            },
        },

        created() {
          this.loadGames();
        },
    };
</script>
<style>
    .custom-file-label::after {
        left: 0;
        right: auto;
        border-left-width: 0;
        border-right: inherit;
    }
</style>
