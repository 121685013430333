<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Import Transactions</h3>
                        </div>
                        
                        <div class="card-header">
                            <div class="card-tools">
                                <form @submit.prevent="handleFileUpload">
                                    <div class="form-group row col-md-6">
                                        <input class="custom-file-input" type="file" ref="fileInput" accept="text/csv" @change="handleFileChange">
                                        <label class="custom-file-label" :class="{ selected: selected }">{{ fileName }}</label>
                                    </div>
                                    <div class="form-group row">
                                        <button class="btn btn-primary" type="submit">Upload CSV</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        
                        <div v-if="csvData" class="card-body table-responsive p-0">
                            <table class="table table-striped">
                                <thead>
                                  <tr>
                                    <th scope="col" v-for="header in csvData[0]">{{ formatHeader(header) }}</th>
                                  </tr>
                                </thead> 
                                <tbody>
                                  <tr v-for="(row, index) in csvData.slice(1)" :key="index">
                                    <!--<td v-for="cell in row">{{ cell }}</td>-->
                                    <td v-for="cell in row">{{ cell }}</td>
                                  </tr>
                                </tbody>
                              </table>
                        </div>
                        
                        <div v-if="csvData" class="card-footer">
                            <button class="btn btn-success" @click="handleImport">Save Transactions</button>
                        </div>
                    </div> <!-- /card -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Papa from 'papaparse/papaparse.min';
    export default {
        data() {
            return {
                csvData: null,
                fileName: 'Select a CSV file',
                selected: false
            };
        },
        methods: {
            handleFileUpload() {
                const fileInput = this.$refs.fileInput;
                const file = fileInput.files[0];
                
                if (file) {
                    this.parseCSV(file);
                } else {
                    Swal.fire(
                        'Error',
                        'Please select a CSV file',
                        'error'
                    );
                }
            },
            handleFileChange(event) {
                const fileInput = event.target;
                this.fileName = fileInput.value.split("\\").pop();
                this.selected = true;
            },
            parseCSV(file) {
                const reader = new FileReader();
                
                reader.onload = (event) => {
                    const parsedData = Papa.parse(event.target.result);
                    
                    if (parsedData.data.length > 0) {
                        this.csvData = parsedData.data;
                    } else {
                        alert('The CSV file is empty or has no valid data.');
                    }
                };
                reader.readAsText(file);
            },
            formatHeader(header) {
                return header
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');
            },
            handleImport() {
                const transactionsImport = JSON.stringify(this.convertCsvDataToAssociativeArray(this.csvData));
                
                axios.post('/api/v1/import/transactions', {
                    'data': transactionsImport
                })
                .then(
                    Swal.fire(
                        'Imported!',
                        'All transactions has been saved.',
                        'success'
                    ),
                    this.csvData = null
                )
                .catch(
                    error => {
                        Swal.fire(
                            'Error!',
                             error.message + ". Please check data to import or contact administrator" ,
                            'error'
                        )
                    }
                )
            },
            convertCsvDataToAssociativeArray() {
                if (!this.csvData || this.csvData.length === 0) {
                    return {};
                }

                const headers = this.csvData[0];
                const data = this.csvData.slice(1);

                return data.map(row => {
                    const rowData = {};
                    headers.forEach((header, index) => {
                        rowData[header] = row[index];
                    });
                    return rowData;
                });
            },
        },
    };
</script>
<style>
    .custom-file-label::after {
        left: 0;
        right: auto;
        border-left-width: 0;
        border-right: inherit;
    }
</style>
